<template>
    <div>
        <section class="ftco-section">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-md-12 heading-section text-center ftco-animated">                        
                        <h2 class="mb-4">{{Topic}}</h2>
                        
                        <b-breadcrumb class="pull-left" :items="bread_items"></b-breadcrumb>

                        <label class="text-secondary pull-right">Last Update: {{LastUpdate}}</label>
                    </div>
                </div>

                <div class="row">
                    <div class="col-sm-12" v-html="HtmlContent">
                        <!-- {{HtmlContent}} -->
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import SiteDataServ from '../services/SiteDataService.vue'

export default {
    data(){
        return{
            Topic:"Test",
            HtmlContent:'<b class="text-center">Content not found.</b>',
            LastUpdate:"01/01/2021 00:00",
            Category:"",
            bread_items:[
                {
                    text: 'Activities',
                    href: '/activities'
                }
            ]
        }
    },
    methods:{
        GetPageContent(){
            SiteDataServ.ActivityGetbyName(this.Topic)
                .then(res => {
                    let _content = res.data;
                    this.HtmlContent = _content.htmldesc;
                    this.LastUpdate = this.DateTimeToText(_content.lastupdate);
                    this.Category = _content.category;
                    let bcate = {
                        text: this.Category,
                        href: '/activities/'+this.Category
                    }
                    this.bread_items.push(bcate);
                    bcate = {
                        text: this.Topic,
                        active: true
                    }
                    this.bread_items.push(bcate);

                })
                .catch(err => {
                    console.log(err);
                });
        },
        DateTimeToText(inp){
            let result = "";
            let Temp = new Date(inp);
            let year = Temp.getFullYear();
            let month = ('0' + (Temp.getMonth() + 1)).slice(-2);
            let date = ('0' + (Temp.getDate())).slice(-2);
            let hour = ('0' + Temp.getHours()).slice(-2);
            let minute = ('0' + Temp.getMinutes()).slice(-2);

            result = date+"/"+month+"/"+year+" "+hour+":"+minute;
            return result;
        }
    },
    mounted(){
        this.Topic = this.$route.params.name;
        this.GetPageContent();
    }
}
</script>

<style scoped>

</style>